body {
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.6, 1.6);
  font-weight: 400;
  color: $blue-dark;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  display: block;
  margin-top: 0;
  margin-bottom: 0.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 2.5rem;
  &.lead {
    margin-bottom: 3rem;
    @include fluid-type($min_width, $max_width, (20px/1.5), 20px, 1.5, 1.5);
      font-weight: 400;
  }
}

h1,.h1 {
  margin-bottom: 2.75rem;
  @include fluid-type($min_width, $max_width, (38.5px/1.5), 38.5px, 1, 1);
  font-weight: 900;
}

h2,.h2 {
  margin-bottom: 1rem;
  @include fluid-type($min_width, $max_width, (28px/1.5), 28px, 1.25, 1.25);
  font-weight: 900;
}

h3,.h3 {
  @include fluid-type($min_width, $max_width, (22.5px/1.5), 22.5px, 1.4, 1.4);
  font-weight: 900;
}

h4,.h4 {
  @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.4/1.2, 1.4);
  font-weight: 900;
}

h5,.h5 {
  @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.4/1.2, 1.4);
  font-weight: 400;
}

h6,.h6 {
  @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.4/1.2, 1.4);
  font-weight: 400;
}