ul.list--default, ul.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list--check {
  @include fluid-type($min_width, $max_width, (17.5px/1.25), 17.5px, (1.6/1.25), 1.6);
  font-weight: 400;

  li {
    position: relative;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: start;
    flex-direction: row;
    &:before {
      position: absolute;
      // @include fluid-type($min_width, $max_width, (24px/1.25), 24px, 1, 1);
      // @extend .hegin-icons;
      // @extend .hegin-icons-check;
      content: "\203A";
      font-weight: bold;
      margin-left: -1.75rem;
      color: #fff;
      font-size: 60px;
      font-weight: 600;
    }
  }
}
.list--plus{
  li{
    &:before{
      content: '+' !important;
      margin-right: 10px;
      position: relative !important;
      font-weight: 600;
      font-size: 25px !important;
      color: $blue-dark !important;
      margin-left: 0px !important;
      top: -6px;
    }
  }
}
ul.list--inline {
  li {
    display: inline-block;
    margin-bottom: 0;
  }
}