blockquote {
  margin: 1rem;
  display: flex;
  text-align: center;
  &:before, &:after {
    margin: 0 0.5rem;
  }
  &:before {
    content: "“";
    @include fluid-type($min_width, $max_width, (64px/1.6), 64px, 1.1, 1.1);
    color: $primary;
    align-self: flex-start;
  }
  &:after {
    content: "”";
    @include fluid-type($min_width, $max_width, (64px/1.6), 64px, 0.5, 0.5);
    color: $primary;
    align-self: flex-end;
  }
}