#navbar{
  position: fixed;
  width: 100vw;
  z-index: 3;
  top: 0px;
  .topbar{
    position: relative;
    margin-top: 0px;
    transition: 0.3s ease-in-out all;
  }
}
@include media-breakpoint-up(lg) {
  .sticky{
    ul.nav-desktop > li {
      padding: 0rem 0.5rem;
    }
  }
}

.sticky{
  .topbar{
  @include media-breakpoint-up(xl) {
    margin-top: -50px !important;
  }
  }
  .image-header__bubble{
    left: -90px;
  }
  .image-header__bubble-right{
    right: -500px;
  }
}
.nav-container {
  position: relative;
  background-color: $white;
  width: 100%;
  z-index: 9;
  transition: 0.3s;

  .navbar {
    margin: 0 auto;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-brand {
      align-items: center;
      justify-content: center;
      margin-right: 1.5rem;
      font-size: 0;
      line-height: inherit;
      z-index: 0;
      display: none;

      img {
        height: auto;
        width: 55px;
      }

      &:focus:not(:hover) {
        outline: 2px dashed rgba($secondary,0.7);
      }
    }
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 93px;
    transition: 0.3s ease-in-out all;
    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, 16px, 16px, 1, 1);
      color: $dark;
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
      text-decoration: none;
      transition: color 0.3s;

      &:not(.btn){
        padding: 0 0.8rem;
        color: $dark;
      }

      &:focus:not(:hover) {
        outline: 2px dashed rgba($secondary,0.7);
      }
    }

    &.has-subitems {
      position: relative;
      &:hover{
        .dropdown-toggle{
          &:before {
            transform: rotate(-180deg);
          }
        }
      }
      .dropdown-toggle {

        &:before {
          content: "\2B07";
          display: inline-block;
          font-size: 20px;
          margin-left: 5px;
          @include transition(0.3s);
          transform: rotate(0deg);
          top: 3px;
          position: relative;
        }
      }
      > a {
        &:before {
          width: 100%;
        }
      }
    }

    //mega submenu
    > .submenu {
      background-color: $white;
      position: absolute;
      top: 100%;
      left: -1rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      border-right: 2px solid rgba($blue-light,0.15);
      border-bottom: 2px solid rgba($blue-light,0.15);
      border-left: 2px solid rgba($blue-light,0.15);

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          margin-left: 2rem;
          width: 160px;
          margin-right: 2rem;
          margin-bottom: 1.5rem;

          @include fluid-type($min_width, $max_width, 17.5px, 17.5px, 1, 1);

          > li {
            position: relative;
            padding: 0.5rem 0;
            break-inside: avoid;

            a {
              position: relative;
              color: $blue-dark;
              text-decoration: none;
              transition: 0.3s;
            }

            &.active, &:hover {
              a {
                color: $blue-light;
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        color: $blue-light;
        font-weight: 700;
      }
    }

    //open submenu
    &:hover {
      &.has-subitems {
        .custom-arrow {
          &:before {
            border-color: $blue-light;
            transform: rotate(135deg) scale(1.5);
          }
        }
      }
      > a:not(.btn) {
        color: $blue-light;
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

#nav-secundary {
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: inline-block;
    > a {
      padding: 0 1rem;
      display: inline-block;
      color: $dark;
      font-weight: 500;
      line-height: 40px;
      text-decoration: none;
      transition: color 0.3s;
      > i {
        font-size: initial;
        vertical-align: middle;
      }
      //focus
      &:focus {
        outline: 2px solid $secondary;
      }
    }
    &:hover {
      > a:not(.btn) {
        color: $primary;
      }
    }
    &:first-of-type {
      i {
        display: none;
      }
    }

  }
}

@include media-breakpoint-down(md) {
  .nav-container {
    .navbar {
      max-width: 100%;
    }
  }

  #nav-secundary {
    > li {
      > a {
        font-size: 0;
      }
      &:first-of-type {
        i {
          display: inline-block;
        }
      }
    }
  }

}

//@include media-breakpoint-up(md) {
//  .nav-container {
//    .navbar {
//      .navbar-brand {
//        img {
//          width: 75px;
//        }
//      }
//    }
//  }
//}

@include media-breakpoint-up(lg) {

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    //position: fixed;
    //top: 0;
    //left: 0;

    &.sticky {
      box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);

      .nav-topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 4rem 1rem;
      letter-spacing: 1px;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.6rem 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  .nav-container {
    .navbar {
      .navbar-brand {
        display: flex;
        img {
          width: 150px;
        }
      }
    }
  }

  ul.nav-desktop {

    > li {
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.6rem 0;
        }
      }
    }
  }
}