.article-section-title {
  margin-bottom: 1.875rem;
  text-align: center;
}

.article {
  position: relative;
  background-color: $white;
  height: 100%;
  width: 100%;
  border: 1px solid #d9e8f3;
  transition: background-color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  header {
    position: relative;
  }

  figure {
    margin: -1px;
    pointer-events: none;
    transition: opacity 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  &__text-label {
    position: absolute;
    right: 10px;
    transform-origin: center;
    top: 0;
    z-index: 1;
    line-height: 22px;
    overflow: hidden;
    width: 22px;
    height: 100%;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 22px;
      padding: 0 1rem;
      color: $white;
      font-weight: 500;
      text-transform: uppercase;
      transform: translate(0%, -50%) rotate(-90deg);
      white-space: nowrap;
      position: absolute;
      right: 50%;
      top: 0;
      transform-origin: right;
      @include fluid-type($min_width, $max_width, (11px/1.2), 11px, 1.4, 1.4);
    }

    &--primary {
      span {
        background-color: $primary;
      }
    }
  }

  &__title {
    padding: 1rem;
  }

  a {
    color: $body-color;
    text-decoration: none;
    outline: none;
    transition: color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:focus:not(:hover) {
      outline: none;
      &:before {
        border: 2px dashed rgba($secondary, 0.7);
      }
    }
  }

  &:hover {
    background-color: $primary;

    figure {
      opacity: 0.8;
    }

    a {
      color: $white;
    }
  }
}