.breadcrumb {
  margin: 0 0 1.5rem 0;
  border-bottom: 2px solid $yellow;
  width: fit-content;
  li {
    @extend .breadcrumb-item;

    a {
      color: $blue-light;
      font-size: 14px;
      text-decoration: none;
      &:focus:not(:hover) {
        outline: 2px dashed rgba($secondary,0.7);
      }
    }

    &.last {
      font-weight: 700;
      @extend .active;
    }
  }
}