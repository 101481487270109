table, .table {
  border-spacing: 0;

  th {
    font-weight: 900;
    text-align: left;
    border-bottom: 1px solid $yellow !important;
    font-size: 20px;
  }

  th,td {
    padding: .5rem 2rem;
  }
}

@include media-breakpoint-up(xl) {
  table, .table {
    margin: 0 auto;
    width: 80%;
  }
}

@include media-breakpoint-up(xxl) {
  table, .table {
    width: 60%;
  }
}

