.cta {
  position: relative;
  background-color: $blue-lighter;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  transition: background-color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &__header { 
    margin-bottom: 1rem;
    img, svg {
      max-width: 80px;
      transition: transform 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }

  h3,.h3 {
    margin-bottom: 0.65rem;
    transition: color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  a {
    display: block;
    @include fluid-type($min_width, $max_width, (12.5px/1.2), 12.5px, 1.6, 1.6);
    color: $primary;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: none;
      transition: background-color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &:after {
      content: "";
      display: inline-block;
      height: 5px;
      width: 5px;
      border-bottom: 4px solid transparent;
      border-left-width: 4px;
      border-left-style: solid;
      border-left-color: initial;
      border-top: 4px solid transparent;
      border-right: 4px solid transparent;
      margin-left: 0.45rem;
    }
    &:focus,&:hover {
      outline: none;
      &:before {
        background-color: rgba($secondary, 0.1);
      }
    }
  }

  &--flex-column {
    flex-direction: column;
    text-align: center;
    .cta__body {
      margin: 0;
    }
  }

  &:hover {
    .cta__header {
      img, svg {
        transform: scale(1.05);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cta {
    &__header {
      img, svg {
        max-width: 110px;
      }
    }

    &__body {
      margin: 0 2% 0 2%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .cta {
    padding: 1.5rem;

    &__header {
      img, svg {
        max-width: 140px;
      }
    }

    &__body {
      margin: 0 15% 0 2%;
    }
  }
}

.c-cta{
  &__inner{
    display: block;
    text-decoration: none !important;
    &:hover{
      .c-cta__bubble-right{
        margin-right: -45vw;
        border: 5px solid #03455f;
      }
      .c-cta__bubble-bottom{
        border: 5px solid #fff;
        left: -195px;
      }
    }
  }
}