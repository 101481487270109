.team {
  margin-top: -8rem;

  .owl-item {
    &:nth-of-type(even) {
      margin-top: 3rem;
    }
  }
}

.member {
  position: relative;

  &:nth-of-type(even){
    margin-top: 2rem;
  }

  .member__description {
    background-color: $blue-lighter;
    width: 96%;
    margin-left: 2%;
    margin-bottom: 3rem;
    padding: 1rem 1.25rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    @include transition(all,0.3s);
    pointer-events: none;
    opacity: 0;

    .member__quote {
      position: relative;
      margin: 2.5rem 0 1rem 0;
      @include fluid-type($min_width, $max_width, (15px/1.5), 15px, 1.5, 1.5);
      font-weight: 400;
      transform: translateX(-10px);
      transition: all 0.2s 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      opacity: 0;

      &:before {
        content: "“";
        position: absolute;
        top: -2.25rem;
        @include fluid-type($min_width, $max_width, (44px/1.5), 44px, 1.5, 1.5);
        font-weight: 900;
        color: $yellow;
      }
    }

    .member__name {
      @include fluid-type($min_width, $max_width, (12.5px/1.5), 12.5px, 1.5, 1.5);
      font-weight: 400;
      text-transform: uppercase;
      opacity: 1;
      transform: translateY(-5px);
      transition: all 0.2s 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      opacity: 0;
    }
  }

  .member__image {
    margin: 0 15%;
    border: 4px solid $white;
    border-radius: 100%;
    cursor: pointer;
    @include transition(border,0.3s);
  }

  &:hover {
    .member__description {
      opacity: 1;

      .member__quote {
        opacity: 1;
        transform: translateX(0);
      }

      .member__name {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .member__image {
      border-color: rgba($blue-dark, 0.2);
    }
  }
}

@media (min-width: 1200px) {
  .member {
    .member__description {
      padding: 1.5rem 2.5rem;

      .member__quote {
        &:before {
          top: -3.25rem;
        }
      }
    }
  }
}