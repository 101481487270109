.sec-title {
  @extend .h1;
  margin-bottom: 2rem!important;
  text-align: center;
}

.sec-link {
  margin-bottom: 3rem!important;
  text-align: center;
}

@include media-breakpoint-up(md) {
  .sec-title {
    margin-bottom: 4rem !important;
  }
}