.share {
  position: relative;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 0;
      margin-right: 8px;
      text-align: center;

      &:before {
        display: none;
      }

      a {
        display: inline-block;
        color: $white;

        i {
          background-color: $blue-dark;
          display: flex;
          justify-content: center;
          align-items: center;
        width: 75px;
          height: 75px;
          border-radius: 100%;
          text-align: center;
          border: 2px solid $blue-dark;
          @include transition(0.3s);
        }

        &:hover {
          text-decoration: none;
          i {
            background-color: transparent;
            color: $blue-dark;
            border: 2px solid $blue-dark;
            transition: 0.3s ease-in-out all;
          }
        }

        &:focus:not(:hover) {
          text-decoration: none;
          outline: none;
          i {
            color: $blue-dark;
          }
        }
      }
    }
  }
}