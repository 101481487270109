.application {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.75rem;

  &__header {
    img {
      margin-right: 0.5rem;
      width: 30px;
    }
  }
  &__body {
    @include fluid-type($min_width, $max_width, (17.5px/1.4), 17.5px, 1.2, 1.2);
  }
}

@include media-breakpoint-up(md) {
  .application {
    &__header {
      img {
        margin-right: 0.75rem;
        width: 40px;
      }
    }
  }
}