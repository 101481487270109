.gallery {
    @include clearfix;
    margin: -9px;
    overflow: hidden;

    a {
        display: inline-block;
        float: left;
        position: relative;
        height: 100%;
        width: 20%;
        text-decoration: none;
        border: 9px solid transparent;

        .bg-progressive {
            @extend .hegin-icons;
            @extend .hegin-icons-search;
            &:before {
                background-color: $primary;
                position: absolute;
                top: 100%;
                left: 0;
                height: 100%;
                width: 100%;

                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

                transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
                z-index: 1;
            }
        }
        &:hover {
            .bg-progressive {
                &:before {
                    top: 0;
                    @include fluid-type($min_width, $max_width, (28px/1.2), 28px, 1.5/1.2, 1.5);
                }
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.gallery {
    &.one-image {
        a {
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.two-image {
        a {
            width: 50%;
        }
    }
    &.three-image {
        a {
            width: 50%;

            @include media-breakpoint-up(sm) {
                width: 33.333%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.more {
        a {
            width: 50%;
            padding-top: 34%;

            &:nth-child(1),&:nth-child(4),&:nth-child(5),&:nth-child(8) {
                width: 60%;
            }
            &:nth-child(2),&:nth-child(3),&:nth-child(6),&:nth-child(7) {
                width: 40%;
            }

            @include media-breakpoint-up(sm) {
                width: 33.333%;
                padding-top: 17%;

                &:nth-child(1),&:nth-child(3),&:nth-child(6),&:nth-child(8) {
                    width: 28%;
                }
                &:nth-child(2),&:nth-child(4),&:nth-child(5),&:nth-child(7) {
                    width: 22%;
                }
            }
        }
    }
}