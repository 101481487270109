$white:         #fff;
$grey:          #3d3d3d;
$black:         #000;

$yellow:         #eec247;
$blue-dark:     #03455f;
$blue-light:    #03667d;
$blue-lightest: #edf2f5;
$blue-lighter:  #edf2f5;

$light:         $blue-lighter;
$dark:          $blue-dark;

$primary:       $blue-dark;
$secondary:     $blue-lightest;

$body-color:    $blue-dark;