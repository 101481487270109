aside {
  .sidebar-item:not(:last-child) {
    margin-bottom: $spacer*1.5;
  }
}

.sidebar {
  position: relative;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,p,ul {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar {
    &-item {
      padding: 0 1rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  aside {
    padding-left: 2rem;
  }
}