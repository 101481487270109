body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}

@include media-breakpoint-up(md) {
  .custom-border-right, .border-right  {
    border-right: 2px solid rgba($yellow, 0.55) !important;
  }
} 

main {
  position: relative;
}

.text-blue-light {
  color: $blue-light;
}

.text-link {
  @include fluid-type($min_width, $max_width, (12.5px/1.2), 12.5px, 1.4, 1.4);
  color: $blue-light;
  font-weight: 500;
  text-transform: uppercase;

  &:focus:not(:hover) {
    outline: 2px dashed rgba($secondary, 0.7);
  }
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($black, 0.85);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, lg)){
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.padding-top--normal {
  padding-top: 1.5rem;
}

.padding-bottom--normal {
  padding-bottom: 1.5rem;
}

.padding-top--large {
  padding-top: 2rem;
}

.padding-bottom--large {
  padding-bottom: 2rem;
}

.padding-top--larger {
  padding-top: 3rem;
}

.padding-bottom--larger {
  padding-bottom: 3rem;
}

.margin-top--normal {
  margin-top: 1.5rem;
}

.margin-bottom--normal {
  margin-bottom: 1.5rem;
}

.margin-offset--normal {
  margin-top: -1.5rem;
}

.margin-top--large {
  margin-top: 2rem;
}

.margin-bottom--large {
  margin-bottom: 2rem;
}

.margin-top--larger {
  margin-top: 3rem;
}

.margin-bottom--larger {
  margin-bottom: 3rem;
}

@include media-breakpoint-down(sm) {
  .border-right {
    border-right: none!important;
  }
}

@include media-breakpoint-up(md) {
  .padding-top--normal {
    padding-top: 2.5rem;
  }

  .padding-bottom--normal {
    padding-bottom: 2.5rem;
  }

  .padding-top--large {
    padding-top: 5rem;
  }

  .padding-bottom--large {
    padding-bottom: 5rem;
  }

  .padding-top--larger {
    padding-top: 8rem;
  }

  .padding-bottom--larger {
    padding-bottom: 8rem;
  }

  .margin-top--normal {
    margin-top: 2.5rem;
  }

  .margin-bottom--normal {
    margin-bottom: 2.5rem;
  }

  .margin-offset--normal {
    margin-top: -2.5rem;
  }

  .margin-top--large {
    margin-top: 5rem;
  }

  .margin-bottom--large {
    margin-bottom: 5rem;
  }

  .margin-offset--large {
    margin-top: -5rem;
  }

  .margin-top--larger {
    margin-top: 8rem;
  }

  .margin-bottom--larger {
    margin-bottom: 8rem;
  }

  .margin-offset--larger {
    margin-top: -8rem;
  }

}