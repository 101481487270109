.language-select {
  background-color: #fff;
  display: inline-block;
  z-index: 10;

  ul {
    padding: 0;
    margin-top: -3px;
    list-style-type: none;
    display: inline-block!important;
    height: 53px;
    //width: 53px;
    overflow: hidden;
    position: absolute;
    z-index: 99;
    transition: height 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    li {
      a {
        padding: 5px 6px;
        display: block;

        &:after {
          content: "";
          display: inline-block;
          height: 6px;
          width: 6px;
          border-bottom: 6px solid transparent;
          border-top-width: 6px;
          border-top-style: solid;
          border-top-color: transparent;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          margin-top: 0.5rem;
          margin-left: 0.25rem;
          border-radius: 3px;
          @include transition(0.2s);
        }

        &:focus:not(:hover) {
          border: 2px dashed rgba($white,0.7);
          outline: none;
        }
      }

      img {
        max-width: 15px;
        height: 15px;
        @include transition(0.2s);
      }

      &:first-of-type {
        a:after {
          border-top-color: inherit;
        }
      }
      &:hover {
        background-color: rgba($secondary, 0.1);
      }
    }

    &:hover {
      background-color: #fff;
      height: 159px;

      li:first-of-type {
        a:after {
          border-top-color: $secondary;
          transform-origin: top;
          transform: translateY(0.4rem) rotate(180deg);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .language-select {
    ul {
      height: 60px;
      &:hover {
        height: 180px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .language-select {
    ul {
      height: 42px;
      &:hover {
        height: 126px;
      }
    }
  }
}