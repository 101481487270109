.site-content {
  margin-bottom: 4rem;

  a:not(.page-link):not(.btn):not(.nav-link) {
    color: $blue-light;
    font-weight: 900;
    text-decoration: none;
    &:focus:not(:hover) {
      outline: 2px dashed rgba($secondary, 0.7);
    }
  }

  ul:not(.specs):not(.pagination):not(.list--check) {
    padding-left: 2.75rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    list-style: none;

    li {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 17%;
        margin-left: -2.75rem;
        width: 10px;
        height: 10px;
        background: $primary;
        display: inline-block;
      }
    }
  }

  form {
    padding-top: 1rem;
  }

  *:last-child {
    margin-bottom: 0 !important;
  }

  table,
  .table {
    width: 100%;
  }
}

.c-news {
  ul {
    margin-top: 40px;
    list-style: none;
    padding-left: 0px;

    li {
      margin-bottom: 30px;
      a{
        text-decoration: none;
        font-weight: unset;
        color: unset;
        font-size: unset;
      }
    }
  }
  .sidebar-item {
    padding: 0px !important;
    margin-left: -6px;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 0px;
  }

  &__date {
    font-weight: 300;
    color: $blue-light;
    opacity: 0.8;
  }
}

@include media-breakpoint-down(lg) {
  .c-usps__bubble-top {
    left: -127.5px !important;
  }
}

@include media-breakpoint-up(xl) {
  .site-content {
    margin-bottom: 0;
    padding-right: 2rem;
  }
}

.c-usps {
  background-color: $blue-dark;
  color: #fff;
  overflow: hidden;

  .list--check {
    li {
      position: relative;
      margin-bottom: 1.25rem;
      display: flex;
      align-content: center;
      align-items: center;
      gap: 20px;
      &::before {
        content: "\203A";
        color: #fff;
        font-size: 60px;
        font-weight: 600;
        display: inline-block;
        position: relative;
        max-height: 40px;
        display: flex;
        align-items: center;
        top: -5px;
      }
    }
  }

  &__bubble-top {
    width: 170px;
    height: 170px;
    border-radius: 100%;
    border: 2px solid $yellow;
    left: 85px;
    z-index: 2;
    position: absolute;
    margin-top: -125px;
  }

  &__bubble-bottom {
    width: 25vw;
    height: 25vw;
    border-radius: 100%;
    border: 2px solid $yellow;
    z-index: 2;
    position: relative;
    right: -87.5vw;
    margin-bottom: -12.5vw;
    margin-top: -12.5vw;
  }

  &__picture-top,
  &__picture-bottom {
    width: 400px;
    height: 400px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__picture-top {
    position: relative;
    margin-bottom: -290px;
    margin-top: -110px;
    margin-left: -140px;
    @include media-breakpoint-down(xl) {
      margin-left: -300px;
      margin-top: -250px;
      margin-bottom: -150px;
    }
  }

  &__picture-bottom {
    position: absolute;
    right: -120px;
    margin-top: -50px;
    @include media-breakpoint-down(xl) {
      width: 200px;
      height: 200px;
      right: -60px;
    }
  }

  &.c-usps-content {

    .c-usps__bubble-bottom {
      position: absolute;
      right: -10vw !important;
      @include media-breakpoint-down(xl) {
        right: -17vw !important;
      }
      
    }
  }
}

.c-cta {
  transition: 0.3s ease-in-out all;
  &__inner {
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: 0.3s ease-in-out all;
  }

  &__content {
    width: 40%;
    background: $blue-dark;
    color: #fff;
    min-width: 250px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    padding: 75px !important;
    border-radius: 0 10% 10% 0 / 15em;
    filter: drop-shadow(30px 0px 25px #03455fa6);

    @include media-breakpoint-down(lg) {
      width: 100%;
      border-radius: 0px;
      margin-top: 200px;
      min-height: 0px;
      height: fit-content;
      padding: 30px !important;
    }
  }

  &__bubble-bottom {
    width: 170px;
    height: 170px;
    border-radius: 100%;
    border: 2px solid $yellow;
    left: -155px;
    z-index: 2;
    position: relative;
    margin-bottom: -145px;
    transition: 0.3s ease-in-out all;
  }

  &__bubble-right {
    height: 50vw;
    width: 50vw;
    display: block;
    position: relative;
    margin-top: -40vw;
    float: right;
    margin-right: -25vw;
    border: 2px solid $yellow;
    border-radius: 100%;
    margin-bottom: -25vw;
    transition: 0.3s ease-in-out all;
  }
}
.cc-window{
  position: fixed;
  right: 0px !important;
  width: 400px;
  max-width: 100vw;
  padding: 30px;
  background: $blue-dark !important;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
  box-shadow: 0 0 30px #ffffff2e;
  bottom: 30px;
  border-radius: 20% 0 0 20%/15em;
  padding-left: 50px;
  .cc-link{
    color: #fff !important;
  }
  &:after{
    content: '';
    border: 2px solid $yellow;
    width: 200px;
    border-radius: 100%;
    position: absolute;
    right: -125px;
    bottom: -75px;
    height: 200px;
    z-index: -1;
  }
}
.cc-btn{
  background: #fff !important;
  color: $blue-dark !important;
  margin-right: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
}