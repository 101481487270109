.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  @include fluid-type($min_width, $max_width, (12.5px/1.2), 12.5px, 1.4, 1.4);
  color: $white;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 18px;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:focus:not(:hover) {
    border: 2px dashed rgba($secondary,0.7);
    outline: none;
    text-decoration: none;
  }

  &-primary {
    background-color: $blue-light;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }

    &--outline {
      background-color: transparent;
      color: $blue-dark;
      border: 2px solid $primary;
      text-decoration: none;

      &:hover {
        background-color: $primary;
        color: $white;
        border: 2px solid $primary;
        text-decoration: none!important;
      }

      &:focus:not(:hover) {
        border: 2px dashed rgba($white,0.7);
      }
    }
  }

  &-secondary {
    background-color: $blue-light;
    color: $white;

    &:hover {
      background-color: darken($blue-light,5%);
      color: $white;
      text-decoration: none;
      border: 2px solid darken($blue-light,5%);
    }

    &--outline {
      background-color: transparent;
      color: $white;
      border: 2px solid $white;

      &:hover {
        background-color: $white;
        color: $secondary;
      }
    }
  }
}

.arrow {
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    height: 5px;
    width: 5px;
    border-bottom: 4px solid transparent;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: initial;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    transform: scale(0, 1);
    margin: 0;
    @include transition(0.3s);
  }

  &:after {
    content: "";
    display: inline-block;
    height: 5px;
    width: 5px;
    border-bottom: 4px solid transparent;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: initial;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    transform: scale(1, 1);
    margin-left: 0.45rem;
    @include transition(0.3s);
  }

  &:hover {
    &:before {
      margin-left: 0.45rem;
      transform: scale(1, 1);
    }
    &:after {
      margin-left: 0;
      transform: scale(0, 1);
    }
  }
}