//custom settings bootstrap components
@import "breadcrumb";
@import "pagination";
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@400;500;600;700&family=Roboto:wght@900&display=swap');

%default-font {
  font-family: 'Geologica', sans-serif;
}

// light 300
// regular 400
// medium 500
// bold 700
// black 900

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width:           60px;

// Components
//
// Define common padding and border radius sizes and more.

$border-width:                2px;
$border-color:                rgba($primary,0.55);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.


$table-accent-bg:             transparent;
$table-border-width:          0;
$table-striped-order:         even;