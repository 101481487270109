.branches {
    @include clearfix;
    overflow: hidden;
}

.branches {
    .branche {
        padding-top: 500px;
        max-height: 60vh;
        @include media-breakpoint-down(lg) {
            padding-top: 300px;
        }
    }
}
.sec-gallery{
    .sec-title{
        margin-bottom: 30px !important;
    }
}
.branche {
    position: relative;
    display: block;
    //float: left;
    height: 100%;
    //width: 20%;
    text-decoration: none;
    overflow: hidden;
    &__bubble-hilight{
        width: 500px;
        height: 500px;
        max-width: 50vw;
        max-height: 50vw;
        position: absolute;
        border-radius: 100%;
        border: 2px solid $yellow;
        right: -380px;
        z-index: 2;
        top: -165px;
        transition: .3s ease-in-out all;
    }
    &:not(.branche--highlighted){
        &:hover{
            .bg-progressive{
                width: 110%;
                height: 110%;
                margin-left: -5%;
                margin-top: -5%;
            }
            .branche__title{
                background: rgba($blue-dark,0.8);
                font-size: 26px;
                text-shadow: 0 0 1px #ffffff7b;
            }
        }
    }
    .bg-progressive {
        margin-left: 0px;
        margin-top: 0px;
        transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &__title {
        // background: linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba($blue-dark,0.9) 90%);
        background: rgba($blue-dark,0.2);
        left: 0px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.25rem;
        @include fluid-type($min_width, $max_width, (17.5px/1.6), 17.5px, 1.6, 1.6);
        color: $white;
        width: 100%;
        height: 100%;
        transition: 0.3s ease-in-out all;
        line-height: 1.2 !important;
        font-size: 25px;
        text-align: center;
    }

    strong {
        font-weight: 900;
    }

    a {
        color: $white;
        text-decoration: none;
        z-index: 1;
        transition: margin-bottom 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    i {
        @include fluid-type($min_width, $max_width, (11px/1.2), 11px, 1.6, 1.6);
    }

    &:hover {
        &:after {
            bottom: 0;
        }

        a {
            margin-bottom: 8px;
        }
    }

    &:focus {
        outline: none;
    }
    &--highlighted {
        .branche__title {
            background: $blue-light;
            padding: 12% 7%;
        }
        a {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .branche {
        &--highlighted {
            .branche__title {
                padding: 13% 12%;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .branche {
        &--highlighted {
            .branche__title {
                padding: 14% 18%;
            }
        }
    }
}