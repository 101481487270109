.image-header-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: 76px;
}

.image-header {
  z-index: 0;
  background-size: 60% 100%, cover;
  background-repeat: no-repeat;
  min-height: 450px;
  right: 0px;
  &__bubble{
    width: 140px;
    height: 140px;
    position: absolute;
    border-radius: 100%;
    border: 2px solid $yellow;
    left: 300px;
    z-index: 2;
    top: -70px;
    transition: 0.3s ease-in-out all;
  }
  &__bubble-right{
    width: 500px;
    height: 500px;
    max-width: 50vw;
    max-height: 50vw;
    position: absolute;
    border-radius: 100%;
    border: 2px solid $yellow;
    right: -50px;
    z-index: 2;
    top: -150px;
    transition: 0.3s ease-in-out all;
  }
  &-overlay {
    min-height: 450px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background-color: $blue-dark;
    border-radius: 0 10% 10% 0 / 15em;
    transition: 0.3s ease-in-out all;
    @include media-breakpoint-down(md) {
      background: transparent;
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1; 
    margin-left: calc(100vw - 1140px);
    @include media-breakpoint-down(lg) {
      margin-left: 30px;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
    // @include media-breakpoint-down(md) {
    //   margin-left: calc(100vw - 540px);
    // }
  }

  &__cta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // margin-left: calc(100vw - 1425px);
    @include media-breakpoint-down(xl) {
      margin-left: 70px;
    }
    a{
      color: $blue-dark;
      position: relative;
      z-index: 1;
      text-decoration: none;
      font-weight: 600;
      font-size: 18px;
      margin-top: -10px;
      padding-bottom: 20px;
      &:before{
        content:"\203A";
        display: inline-block;
        font-size: 30px;
        margin-right: 5px;
        top: 2px;
        position: relative;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0; 
      top: 0;
      bottom: 0;
      background-color: $secondary;
      width: 59%;
      padding-top: 30%;
      transform: translate(-16%, -20%);
      border-top-left-radius: 79%;
      border-top-right-radius: 165%;
      min-width: 400px;
    }
  }

  &__background {
    position: relative;
    min-height: 160px;
  }

  &__text {
    margin-bottom: 80px;

    color: $white;
  }

  &__title {
    @include fluid-type($min_width, $max_width, 22px, 44px, 1.25, 1.25);
    color: $white;
    font-weight: 900;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 1rem;
    }
    max-width: 600px;
    @include media-breakpoint-down(md) {
      background: $blue-dark;
      padding: 30px;
    }
  }

  &__usp {
    background-color: rgba($blue-dark,0.8);
    align-self: flex-end;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    width: 100%;
    z-index: 1;
    .owl-usp-carousel {
      padding: 0;
      margin: 0;
      list-style: none;
      @include fluid-type($min_width, $max_width, 14px, 14px, 1, 1);
      font-weight: 500;
      color: $white;
      .item {
        display: block;
        i {
          margin-right: .5rem;
          @include fluid-type($min_width, $max_width, 20px, 20px, 1, 1);
          color: $primary;
          vertical-align: middle;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-header {

    &__cta {
      display: flex;
      margin-top: 2rem;
      .btn:not(:last-child){
        margin-right: 1rem;
      }
    }

    &__usp {
      position: absolute;
      bottom: 0; 
    }

    &__background {
      min-height: 220px;
    }

    //specific styling for homepage
    &--home {
      .image-header__background {
        min-height: 320px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .image-header-wrapper {
    margin-top: 75px;
  }
}
@include media-breakpoint-up(lg) {
  .image-header-wrapper {
    margin-top: 180px;
  }
  .image-header {

    &__usp {
      .owl-usp-carousel {
        .owl-item {
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }
  }
}
.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white!important;
  transform: translateY(-50%);
  border-radius: 50px;
  outline: none;
  transition: 0.3s;

  span {
    display: block;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
  }

  &:hover {
    background: $primary!important;
    span {
      border-color: $white;
    }
  }

  &:focus {
    border: 2px solid $secondary;
  }

  &.disabled {
    background: rgba($white,0.5)!important;
    &:hover, &:focus {
      border:none;
      span {
        border-color: $secondary;
      }
    }
  }
}
.owl-theme .owl-nav {
  margin: 0;
}


.owl-carousel .owl-nav .owl-prev {
  left: -70px;
  span {
    margin-right: -74px;
    border-bottom: 1px solid;
    border-left: 1px solid;
  }
}

.owl-carousel .owl-nav .owl-next {
  right: -70px;
  span {
    margin-left: -74px;
    border-top: 1px solid;
    border-right: 1px solid;
  }
} 