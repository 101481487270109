nav {
    ul.pagination {
        margin: 1.5rem 0;
    }
    li.page-item {
        margin: 0 0.25rem;
        a {
            line-height: 18px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
        }
        &.previous {
            .custom-arrow-pagination:before {
                transform: rotate(180deg);
            }
        }

        a.page-link {
            height: 36px;
            width: 36px;
            position: relative;
            vertical-align: middle;
            border-radius: 0!important;
            box-shadow: none;
            @include transition(background-color 0.3s,color 0.3s);

            &:hover {
                background-color: $primary;
                color: $white;
            }

            &:focus:not(:hover) {
                outline: 2px dashed rgba($secondary,0.7);
            }
        }

        &.disabled {
            a.page-link {
                color: rgba($black,0.2);
            }
        }
    }
}

//custom arrow
.custom-arrow {
    position: relative;
    display: flex;
    min-width: 10px;
    align-items: center;
    justify-content: center;

    &:before {
        content: "";
        width: 5px;
        height: 5px;
        border-left: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
        transform: rotate(45deg);
    }
}

//custom pagination arrow
.custom-arrow-pagination {
    position: relative;
    display: flex;
    min-width: 10px;
    align-items: center;
    justify-content: center;

    @extend .hegin-icons;
    @extend .hegin-icons-arrow-next;
}