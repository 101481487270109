footer {
  position: relative;
  padding: 5.5rem 0;
  @include fluid-type($min_width, $max_width, 15px, 15px, 1.6, 1.6);
  color: $blue-dark;
  text-align: center;
  font-size: 18px !important;
  background-color: $blue-lighter;
  background-image: url('/images/wave.png');
  background-size: 100% 100%;
  background-position: bottom;
  h3,.h3 {
    margin-bottom: 1.25rem;
  }

  a {
    color: $blue-dark;
    text-decoration: none;
    transition: color 0.3s;
    outline: none;
    padding-bottom: 10px;
    display: block;
    font-size: 18px;
    &.highlighted {
      color: $blue-dark;
    }

    &:hover {
      color: $blue-dark;
      text-decoration: underline;
    }

    &:focus:not(:hover) {
      text-decoration: underline;
    }
  }

  p:last-of-type {
    margin: 0;
  }

  ul {
    margin-bottom: 2rem;
    li {
      margin-bottom: 0.2rem;
    }
  }

  .btn {
    margin-bottom: 1rem;
  }
}

.bg-light {
  + footer {
    &:before {
      background-color: $blue-lighter;
    }
  }
}

@include media-breakpoint-up(md) {
  footer {
    text-align: left;

    .custom-border-right {
      border-color: transparent!important;
    }
  }
}

@include media-breakpoint-up(lg) {
  footer {
    .custom-border-right {
      border-color: rgba($white,0.55)!important;
    }
  }
}