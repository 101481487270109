.topbar {
  background-color: $secondary;

  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1.5rem 0 0;
    font-size: 0;
    line-height: inherit;
    z-index: 0;

    img {
      height: auto;
      width: 55px;
    }
  }

  ul {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      @include fluid-type($min_width, $max_width, (15px/1.2), 15px, 1, 1);
      font-weight: 500;
      color: $dark;
      @include transition(0.3s);

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        height: 53px;
        color: $blue-light;
        text-decoration: none;
        font-weight: 300;
        &:focus:not(:hover) {
          text-decoration: underline;
          outline: none;
        }

        &.active,&:hover {
          text-decoration: underline;
        }
      }

      i {
        font-size: 120%;
        text-decoration: none;
      }

      :focus {
        outline: none;
      }
    }
  }

  #phone {
    li {
      font-weight: 400;

      a {
        &.active, &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          text-decoration: none;
        }
      }

      i {
        margin-right: 0.5rem;
        text-decoration: none;
      }
      span {
        display: none;
      }
    }
  }

  #nav-top {
    margin-left: auto;
  }
}

@include media-breakpoint-down(lg) {
  .topbar {

    .navbar-brand {
      margin: 0 1rem 0 0.5rem;
    }

    .container {
      padding: 0;
      max-width: none;
    }

    #phone {
      li {
        a {
          padding-left: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .topbar {
    ul {
      li {
        a {
          height: 60px;
        }

        i {
          margin-right: 1rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .topbar {
    .navbar-brand {
      display: none;
    }

    ul {
      li {
        a {
          height: 35px;
        }
      }
    }

    #phone {
      li {
        a {
          margin-left: -1.25rem;
        }
        span {
          display: inline-block;
          margin-right: 0.25rem;
        }
      }
    }
  }
}