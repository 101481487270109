@font-face {
	font-family: "hegin-icons";
	src: url('../fonts/hegin-icons.eot');
	src: url('../fonts/hegin-icons.eot?#iefix') format('eot'),
		url('../fonts/hegin-icons.woff2') format('woff2'),
		url('../fonts/hegin-icons.woff') format('woff'),
		url('../fonts/hegin-icons.ttf') format('truetype'),
		url('../fonts/hegin-icons.svg#hegin-icons') format('svg');
}

.hegin-icons:before {
	font-family: "hegin-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.hegin-icons-arrow-next:before {
	content: "\E001";
}

.hegin-icons-check:before {
	content: "\E002";
}

.hegin-icons-double-arrow:before {
	content: "\E003";
}

.hegin-icons-email:before {
	content: "\E004";
}

.hegin-icons-facebook:before {
	content: "\E005";
}

.hegin-icons-files-and-folders:before {
	content: "\E006";
}

.hegin-icons-filing-cabinet:before {
	content: "\E007";
}

.hegin-icons-instagram:before {
	content: "\E008";
}

.hegin-icons-iphone:before {
	content: "\E009";
}

.hegin-icons-linkedin:before {
	content: "\E00A";
}

.hegin-icons-marker:before {
	content: "\E00B";
}

.hegin-icons-phone:before {
	content: "\E00C";
}

.hegin-icons-pinterest:before {
	content: "\E00D";
}

.hegin-icons-plug:before {
	content: "\E00E";
}

.hegin-icons-route:before {
	content: "\E00F";
}

.hegin-icons-search:before {
	content: "\E010";
}

.hegin-icons-twitter:before {
	content: "\E011";
}

.hegin-icons-vimeo:before {
	content: "\E012";
}

.hegin-icons-whatsapp:before {
	content: "\E013";
}

.hegin-icons-youtube:before {
	content: "\E014";
}
